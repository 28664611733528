<template>
  <v-card-text class="mt-5 px-md-12 px-sm-5">
    <app-form ref="form" :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"
      footerClass="col-12 text-center" disableCancelButton />
  </v-card-text>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  name: 'EditPassword',
  data() {
    return {
      submitButton: {
        label: this.$t('actions.save')
      },
      fields: {
        email: {
          type: 'text',
          key: 'email',
          value: '',
          disabled: true,
          label: this.$t('models.user.attributes.email'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0'
        },
        oldPassword: {
          type: 'password',
          key: 'oldPassword',
          value: '',
          hidden: false,
          rules: [],
          label: this.$t('models.user.attributes.password'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        newPassword: {
          type: 'password',
          key: 'password',
          value: '',
          label: this.$t('models.user.attributes.newPassword'),
          rules: [{
            name: 'required'
          }, {
            name: 'min',
            length: 8
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        passwordConfirmation: {
          type: 'password',
          key: 'passwordConfirmation',
          value: '',
          label: this.$t('models.user.attributes.confirmPassword'),
          rules: [{
            name: 'required'
          }, {
            name: 'min',
            length: 8
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        }
      }
    };
  },
  computed: {...mapGetters(['currentUser', 'isAdmin', 'user'])},
  methods: {
    ...mapActions(['updateUserPassword', 'getUser']),
    handleSubmit(data) {
      this.updateUserPassword({...data, id: this.user.id}).then((response) => {
        if (!this.successCode(response.status)) {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    }
  },
  created() {
    this.getUser({uuid: this.$route.params.uuid, fields: ['email', 'avatar', 'firstName', 'lastName']}).then(() => {
      this.fields.email.value = this.user.email;
      this.fields.oldPassword.rules = this.isAdmin && (this.currentUser.id !== this.user.id) ? [] : [{name: 'required'}];
      this.fields.oldPassword.hidden = this.isAdmin && (this.currentUser.id !== this.user.id);
    });
  }
};
</script>

